import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "gatsby";
import config from "../../config";
import Footer from "../section/footer";
import LoginControl from "../../controls/login";
import ForgotPassword from "../forgot-password/index";
import Header from "../section/header";
import Banner from "../section/banner";

import MfaPanel from "./mfa";

const LoginPage = (props) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  const [mfaMethod, setMfaMethod] = useState(null);
  const [allowedOptions, setAllowedOptions] = useState(["admin-generated-otp"]);
  const [mfa, setMfa] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (userData) {
      setShowMfa(false);
    }
  }, []);

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  };

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  };

  return (
    <Fragment>
      <Header />
      <Banner>
        <Row className="fh-element align-items-center">
          <Col>
            {showForgotPassword && (
              <ForgotPassword onClose={onCloseForgotPassword} />
            )}
            <Container>
              <Row>
                <Col lg={8} xl={6} className="mb-5 mb-lg-0">
                  <Modal
                    show={showMfa}
                    className="login-mfa-panel-modal"
                    onHide={() => {}}
                  >
                    <MfaPanel
                      mfaMethod={mfaMethod}
                      setShowMfa={setShowMfa}
                      mfa={mfa}
                      allowedOptions={allowedOptions}
                      setMfaMethod={setMfaMethod}
                      userData={userData}
                      setUserData={setUserData} 
                    />
                  </Modal>
                  {!showMfa && (
                    <Container>
                      <div className="login-container">
                        <div className="title mb-2">{"Login"}</div>
                        <LoginControl
                          authUrl={config.auth}
                          portalUrl={config.portal}
                          apiUrl={config.backend}
                          setMfa={setMfa}
                          setShowMfa={setShowMfa}
                          setAllowedOptions={setAllowedOptions}
                          setUserData={setUserData}
                        >
                          <Col xs={12} sm={6} className="vw-loginbtn">
                            <a
                              className="forgot-password"
                              onClick={handleClickForgotPassword}
                            >
                              {"Forgot Password?"}
                            </a>
                          </Col>
                        </LoginControl>
                        <Container>
                          <Row className="row-line-divider">
                            <Col className="p-0 d-flex align-items-left">
                              <Link
                                to="/"
                                className="ml-auto mt-3 btn-black-text"
                              >
                                {"Sign up here"}
                              </Link>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Container>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Banner>
      <Footer />
    </Fragment>
  );
};

export default LoginPage;
