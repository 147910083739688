import React, {useEffect, useRef} from "react";
import { Container} from "react-bootstrap";
import bgData from "../../images/bg/home-bg.png";
import { executeScroll } from "./utils";
import { useQueryParam, StringParam } from "use-query-params";

export default function Banner(props) {
  const myRef = useRef(null);
  const [defaultTab] = useQueryParam("section", StringParam);
  useEffect(() => {
    if (defaultTab === "join") {
      executeScroll(myRef);
    }
  }, [defaultTab, myRef, executeScroll]);

  return (
    <div className="banner align-items-center d-flex"
      ref={myRef}
      style={{
        background: `url(${bgData})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* <img src={bgData} alt="background-city" className="banner-img-whole" /> */}
      <Container className="banner-content">
        {props.children}
      </Container>
    </div>
  );
}
