import { useQuery } from "@apollo/client";
import React from "react"
import { getCurrentUserQuery } from "../logic/user";
import LoginPage from "./login-page";
import Loader from "../bootstrap/loader";

const UserCheck = (props) => {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;

  if (user) {
    return window.location = "/portal";
  }

  return (
    <LoginPage {...props} />
  );
}

export default UserCheck