import React, { Fragment } from "react";
import Formsy from "formsy-react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import TextField from "../bootstrap/input";
import { useAsyncSetState } from "use-async-setstate";
import { useRef } from "react";
import { fetch } from "window-or-global";

export default function Verification(props) {
  const { setStateVerification } = props;
  const [loading, setLoading] = useAsyncSetState(false);
  const formRef = useRef(null);

  const handleVerify = async (data) => {
    await setLoading(true);

    try {
      const { code } = data;
      if (!code) {
        await setLoading(false);
        return;
      }

      const apiUrl = process.env.BACKEND_URL;

      let result = await fetch(
        `${apiUrl}rest.api/forgot-verification/${code}`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      result = await result.json();
      if (result && result?.success && result?.redirectUrl) {
        window.location.href = result?.redirectUrl;
      }
    } catch (err) {
      console.log({ err });
      await setLoading(false);
    }

    await setLoading(false);
  };

  return (
    <Fragment>
      <Modal.Body className="fp-modal-body">
        <Formsy
          ref={formRef}
          onValidSubmit={async (data) => {
            return handleVerify(data);
          }}
          onInvalidSubmit={(e) => {
            console.log("error", e);
          }}
        >
          <Container fluid className="no-gutters">
            <Row>
              <Col xs={12}>
                <p className="mb-0 verification-text mb-3">
                  {
                    "A sms or email has been sent to your device, enter the one time code to verify your account to reset your password."
                  }
                </p>
                <p className="mb-0 verification-text">
                  {
                    "If you do not receive a verification code, please go back and check your details and try again."
                  }
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="alert alert-primary mt-3 mb-0">
                  {"Enter verification code to continue"}
                </div>
              </Col>
            </Row>
            <Row className="field-row">
              <Col>
                <TextField
                  disabled={loading}
                  type={"number"}
                  autoFocus
                  inputMode="numeric"
                  name="code"
                  placeholder="Verification Code"
                  defaultValue=""
                  required
                  autoComplete="one-time-code"
                />
              </Col>
            </Row>
          </Container>
        </Formsy>
      </Modal.Body>
      <Container>
        <Row>
          <Col xs="auto mr-auto">
            <Button
              variant="black-text"
              onClick={async () => {
                setStateVerification(false);
              }}
            >
              <i className="fas fa-arrow-left mr-2" />
              {"Return"}
            </Button>
          </Col>
          <Col xs="auto ml-auto">
            <Button
              type="submit"
              variant="yellow"
              className="vw-button"
              disabled={loading}
              onClick={() => formRef.current.submit()}
            >
              {"Reset Password"}
            </Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
