import React from 'react';


const MfaOption = ({ label, hint, onClick, disabled, icon }) => {
  return (
    <div
      className={`mfa-option ${disabled && "disabled"}`}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
    >
      {icon}
      <div className="mfa-option-content">
        <div className="mfa-option-label">{label}</div>
        <p>{hint}</p>
      </div>
      <i className="fa fa-angle-right" />
    </div>
  );
};


const MFAselectPanel = (props) => {
  const {allowedOptions, setMfaMethod, setShowMfa} = props;

  const MFA_OPTIONS = [
    {
      label: "SMS Authentication",
      hint: "Receive authentication code on your registered mobile number.",
      value: "sms",
      icon: <i className="fas fa-comment" />,
    },
    {
      label: "Email Authentication",
      hint: "Receive authentication code on your registered email address.",
      value: "email",
      icon: <i className="fas fa-envelope" />,
    },
    {
      label: "2FA Authentication",
      hint:
        "Receive authentication code on your registered authenticator app.",
      value: "user-totp",
      icon: <i className="fas fa-key" />,
    },
    {
      label: "I have a one-time-password",
      hint: "You received authentication code from your system admin.",
      value: "admin-generated-otp",
      icon: <i className="fal fa-key" />,
    },
  ];

  return (
    <div className="mfa-panel">
      <p className="mfa-heading">{"Verify your account"}</p>
      <p>{"Select an one-time verification method."}</p>
      <p>
        {
          "This is a process called Multi-Factor Authentication (MFA), which has been introduced to strengthen the security of your account."
        }
      </p>
      <div className="mfa-options">
        {MFA_OPTIONS.map((option) => ({
          ...option,
          disabled: !allowedOptions.find((ao) => ao === option.value),
        }))
          .filter((o) => !(o.value === "user-totp" && o.disabled)) // totally hides user-totp option when no authenticator setup
          .map((option) => (
            <MfaOption
              key={option.label}
              label={option.label}
              hint={option.hint}
              disabled={option.disabled}
              onClick={() => {
                setMfaMethod(option.value);
              }}
              icon={option.icon}
            />
          ))}
      </div>
      <div className="mfa-other-options-container">
        <span
          onClick={() => {
            setShowMfa(false);
            setMfaMethod(null);
          }}
        >
          {"Cancel"}
        </span>
      </div>
    </div>
  );
};

export default MFAselectPanel;