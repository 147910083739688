import React, { createContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  createVerificationMutation,
  getAdminGeneratedVerification,
  resendVerificationMutation,
  validateVerification,
} from "../../logic/user";
import DataHelper from "../../utils/data-helper";
import config from "../../../config";
import { Col, Row } from "react-bootstrap";
import MFAselectPanel from "./panels/mfa-select-panel";
import MFAEmailPanel from "./panels/mfa-email-panel";
import MFAAdminGeneratedPanel from "./panels/mfa-admin-generated-panel";
import MFATotpPanel from "./panels/mfa-totp-panel";
import MFASmsPanel from "./panels/mfa-sms-panel";

const OTP_VALIDITY = 600;

export const mfaContext = createContext();

const MfaPanel = (props) => {
  const {
    mfaMethod,
    setShowMfa,
    allowedOptions,
    setMfaMethod,
    userData,
    setUserData,
    mfa,
  } = props;
  const [resendVerfication, { loading }] = useMutation(
    resendVerificationMutation
  );
  const [requestCode, setRequestCode] = useState(null);
  const [retryEnabled, setRetryEnabled] = useState(false);
  const [retryTimer, setRetryTimer] = useState(0);

  const [createVerification] = useMutation(createVerificationMutation);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpError, setOtpError] = useState(null);
  const [inputFocus, setInputFocus] = useState(null);
  const [otpLoading, setOtpLoading] = useState(false);
  const [authDetails, setAuthDetails] = useState(false);

  const formRef = useRef(null);

  const {
    data: validateVerificationData,
    startPolling,
    stopPolling,
  } = useQuery(validateVerification, {
    variables: { requestCode },
  });
  const requestCodeVerified =
    validateVerificationData?.classMethods?.Verification?.validateVerification;

  const { data } = useQuery(getAdminGeneratedVerification, {
    variables: {
      userId: DataHelper.toGlobalId("User", userData?.id),
    },
  });

  const activeVerification =
    data?.classMethods?.Verification?.adminGeneratedVerification;

  useEffect(() => {
    if (requestCodeVerified) {
      handleApprove();
      stopPolling();
    }
  }, [requestCodeVerified]);

  useEffect(() => {
    let interval;

    if (retryTimer === 0) {
      setOtp(Array(6).fill("")); // resets otp value
      setInputFocus(null); // resets input focus
      setOtpError(null); // resets otp error
    }

    if (retryTimer > 0 && !requestCodeVerified) {
      interval = setInterval(() => {
        setRetryTimer((prev) => {
          const newTime = prev - 1;

          if (newTime === 0) {
            stopPolling();
            setRetryEnabled(true);
          }

          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [retryTimer]);

  useEffect(() => {
    if (otp.join("").length === 6) {
      handleOtpSubmit(otp.join(""));
    }
  }, [otp]);

  useEffect(() => {
    setOtp(Array(6).fill("")); // resets otp value
    setOtpError(null); // resets otp error
    setInputFocus(null); // resets input focus

    if (mfaMethod === "sms") {
      createVerification({
        variables: {
          type: "SMS",
          userId: DataHelper.toGlobalId("User", userData?.id),
          name: "Sms Verification",
          template: "2fa-portal",
        },
        awaitRefetchQueries: true,
      }).then((result) => {
        const { data } = result;
        setRequestCode(
          data?.classMethods?.Verification?.createVerification?.id
        );
        setRetryTimer(OTP_VALIDITY);
        startPolling(500);
      });
    } else if (mfaMethod === "email") {
      createVerification({
        variables: {
          type: "EMAIL",
          userId: DataHelper.toGlobalId("User", userData?.id),
          name: "Email Verification",
          template: "2fa-portal",
        },
        awaitRefetchQueries: true,
      }).then((result) => {
        const { data } = result;
        setRequestCode(
          data?.classMethods?.Verification?.createVerification?.id
        );
        setRetryTimer(OTP_VALIDITY);
        startPolling(500);
      });
    } else if (mfaMethod === "user-totp") {
      createVerification({
        variables: {
          type: "TOTP",
          userId: DataHelper.toGlobalId("User", userData?.id),
          name: "Totp Verification",
        },
        awaitRefetchQueries: true,
      }).then((result) => {
        const { data } = result;
        setRequestCode(
          data?.classMethods?.Verification?.createVerification?.id
        );
      });
    }
  }, [mfaMethod]);

  const handleRetry = () => {
    setOtp(Array(6).fill("")); // resets otp value
    setInputFocus(null); // resets input focus
    setOtpError(null); // resets otp error

    if (requestCode) {
      resendVerfication({
        variables: { requestCode },
      }).then(() => {
        setRetryEnabled(false);
        setRetryTimer(OTP_VALIDITY);
        startPolling(1000);
      });
    }
  };

  const handleApprove = async (verificationId = null) => {
    try {
      const apiPath = config.backend;
      const data = await fetch(`${apiPath}rest.api/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          ...mfa.loginDetails,
          requestCode: verificationId || requestCode,
        }),
      });

      if (data?.status === 200) {
        const parsedData = await data.json();

      if (parsedData.jwtToken) {
        localStorage.setItem("authToken", parsedData.jwtToken);
      }

        setAuthDetails(mfa.loginDetails);

      if (config.auth && config.auth !== "") {
        formRef.current.submit();
      }

        window.location.href = config.portal;

        return window.location.href
      }

      console.log("503 redirect?"); // eslint-disable-line
      return setShowMfa(false);
    } catch (err) {
      console.log("ERROR >>> ", err); // eslint-disable-line
      console.log("err 503 redirect?"); // eslint-disable-line
      return undefined;
    }
  };  

  const handleOtpSubmit = async (otp) => {
    setOtpLoading(true);
    const apiPath = config.backend;
    const data = await fetch(
      `${apiPath}rest.api/verify/${requestCode}:${otp}`,
      {
        method: "GET",
      }
    );

    const parsedData = await data.json();

    if (parsedData.success) {
      handleApprove();
    } else {
      setOtpError("Invalid code");
    }

    setOtpLoading(false);
  };

  return (
    <mfaContext.Provider
      value={{
        otpLoading,
        activeVerification,
        handleApprove,
        loading,
        DataHelper,
        userData,
        handleOtpSubmit,
        setOtp,
        setOtpError,
        otp,
        inputFocus,
        setInputFocus,
        otpError,
        requestCodeVerified,
        retryEnabled,
        handleRetry,
        retryTimer,
        setMfaMethod,
        stopPolling,
        setRetryTimer,
        setShowMfa,
        setUserData,
      }}
    >
      <form ref={formRef} name="login" action={config.auth} method="post">
        <input type="hidden" value="hotspot" name="radius11" />
        <input type="hidden" name="dst" value={`${config.portal}`} />
        <input type="hidden" name="username" value={authDetails?.userName} />
        <input type="hidden" name="password" value={authDetails?.password} />
      </form>
      <Row>
        <Col xs={12}>
          {!mfaMethod && <MFAselectPanel allowedOptions ={allowedOptions} setMfaMethod={setMfaMethod} setShowMfa={setShowMfa} />}
          {mfaMethod === "sms" && <MFASmsPanel />}
          {mfaMethod === "email" && <MFAEmailPanel />}
          {mfaMethod === "user-totp" && <MFATotpPanel />}
          {mfaMethod && mfaMethod === "admin-generated-otp" && <MFAAdminGeneratedPanel />}
        </Col>
      </Row>
    </mfaContext.Provider>
  );
};

export default MfaPanel;
